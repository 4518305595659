<template>
  <b-modal
    id="inlandsfluegeBearbeitenModal"
    title="Inlandsflüge bearbeiten"
    @show="onShow"
    @hide="onHide"
    hide-footer
  >
    <b-overlay :show="isLoading">
      <div
        v-for="(inlandsflug, i) in inlandsflugData"
        :key="inlandsflug.tag + '-' + inlandsflug.abflughafenIataCode"
        class="mb-8"
      >
        <h6 class="mb-3">{{ i + 1 }}. Inlandsflug</h6>
        <b-list-group horizontal class="bv-example-row">
          <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
            <h6 class="my-1 leftLabel">Tag</h6>
          </b-list-group-item>
          <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut" :class="{}">
            <h6 class="my-1 leftLabel">{{ inlandsflug.tag }}</h6>
          </b-list-group-item>
        </b-list-group>
        <b-list-group horizontal class="bv-example-row">
          <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
            <h6 class="my-1 leftLabel">Abflughafen</h6>
          </b-list-group-item>
          <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut" :class="{}">
            <h6 class="my-1 leftLabel">{{ inlandsflug.abflughafenIataCode }}</h6>
          </b-list-group-item>
        </b-list-group>
        <b-list-group horizontal class="bv-example-row">
          <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
            <h6 class="my-1 leftLabel">Flugnummer</h6>
          </b-list-group-item>
          <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut" :class="{}">
            <b-form-input
              class="border-0 p-2 leftLabel"
              debounce="1000"
              v-model="inlandsflug.editFlugnummer"
              @update="changedFlugnummerOrInGruppeInkludiert(inlandsflug)"
            />
          </b-list-group-item>
        </b-list-group>
        <b-list-group horizontal class="bv-example-row">
          <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
            <h6 class="my-1 leftLabel">In Gruppe inkludiert</h6>
          </b-list-group-item>
          <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut" :class="{}">
            <b-form-checkbox
              size="lg"
              class="leftLabel"
              v-model="inlandsflug.editInGruppeInkludiert"
              @change="changedFlugnummerOrInGruppeInkludiert(inlandsflug)"
            ></b-form-checkbox>
          </b-list-group-item>
        </b-list-group></div
    ></b-overlay>
  </b-modal>
</template>

<script>
import apiService from '@/core/common/services/api.service';

export default {
  props: { data: { type: Array } },
  data() {
    return {
      inlandsflugData: [],
      isLoading: false,
    };
  },
  methods: {
    changedFlugnummerOrInGruppeInkludiert(inlandsflug) {
      this.isLoading = true;
      const { tag, abflughafenIataCode, editFlugnummer, editInGruppeInkludiert } = inlandsflug;
      const dataInlandsflug = this.inlandsflugData.find(
        inlandsflug => inlandsflug.tag === tag && inlandsflug.abflughafenIataCode === abflughafenIataCode
      );
      const reiseterminIdToInlandsflugId = dataInlandsflug.reiseterminId_inlandsflugId.reduce((acc, curr) => {
        const [reiseterminId, inlandsflugId] = curr.split(':');
        acc[reiseterminId] = +inlandsflugId;
        return acc;
      }, {});
      apiService
        .put('Reisetermin/metadata/inlandsflug/bulkUpdateFlugnummerAndInGruppeInkludiert', {
          reiseterminIdToInlandsflugId,
          flugnummer: editFlugnummer,
          inGruppeInkludiert: editInGruppeInkludiert,
        })
        .then(res => {
          this.toast('Flugnummer gespeichert!');
          this.$emit('inlandsflugMultipleFlugnummerChanged', {
            flugnummer: editFlugnummer,
            inGruppeInkludiert: editInGruppeInkludiert,
            reiseterminIdToInlandsflugId,
          });
        })
        .finally(() => (this.isLoading = false));
    },
    onHide() {
      this.inlandsflugData = [];
    },
    onShow() {
      this.inlandsflugData = this.data
        .slice()
        .sort((a, b) => a.Tag - b.Tag)
        .map(inlandsflug => ({
          ...inlandsflug,
          editFlugnummer: inlandsflug.flugnummer,
          editInGruppeInkludiert: inlandsflug.inGruppeInkludiert,
        }));
    },
  },
};
</script>
<style scoped>
:deep(.modal-footer) {
  padding: 5px;
}
.detailLabelGreyedOut {
  background-color: rgba(114, 114, 114, 0.05);
  border-style: solid;
  border-color: rgba(114, 114, 114, 0.15);
}
.leftLabel {
  font-size: 1.05rem;
  font-weight: 500;
}
.list-group {
  height: 42px;
}
:deep(.form-control) {
  height: 32px !important;
}
</style>
