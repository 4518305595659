<template>
  <b-modal
    ref="reisetermineBearbeitenModal"
    id="reisetermineBearbeitenModal"
    size="xl"
    :title="title"
    @show="onShow"
    @hide="onHide"
    centered
    class="m-0 p-0"
    no-close-on-backdrop
    no-close-on-esc
  >
    <b-overlay :show="isLoading">
      <div>Felder zum Bearbeiten auswählen:</div>
      <div class="d-flex align-items-center mt-2" style="gap: 8px">
        <Multiselect
          v-model="fieldsToEdit"
          :options="orderedFieldEditOptions"
          selectLabel=""
          selectGroupLabel=""
          deselectLabel=""
          deselectGroupLabel=""
          :close-on-select="false"
          :searchable="false"
          :multiple="true"
          label="label"
          track-by="key"
          selectedLabel="Ausgewählt"
          :placeholder="null"
          class="w-75"
          size="md"
        ></Multiselect>
        <i
          v-if="fieldsToEdit.length > 0"
          class="fas fa-times text-danger align-self-center"
          @click="fieldsToEdit = []"
          style="cursor: pointer"
        ></i>
      </div>
      <div class="mt-4 grid">
        <div v-for="item in fieldsToEdit" :key="item.label">
          <b-form-group :label="item.label">
            <b-form-input
              v-if="item.type === 'number'"
              size="sm"
              v-model="item.value"
              :number="true"
              type="number"
              min="0"
              max="365"
              step="1"
              required
            ></b-form-input>

            <b-form-input
              v-if="item.type === 'input'"
              size="sm"
              v-model="item.value"
              class="p-2 leftLabel"
              placeholder="-"
            ></b-form-input>

            <b-form-select
              v-if="item.type === 'select'"
              size="sm"
              v-model="item.value"
              :options="item.selectOptions"
            ></b-form-select>

            <AirportsSucheMultiselect
              v-if="item.type === 'flughafen'"
              v-model="item.value"
              :isMultipleAllowed="false"
              :openDirection="'top'"
              id="abflughafen"
              :filterCountry="country"
            ></AirportsSucheMultiselect>
          </b-form-group>
        </div>
      </div>
    </b-overlay>
    <template #modal-footer="{ hide }" class="pl-1">
      <b-button
        class="mr-4"
        @click="handleSubmit(hide)"
        :disabled="fieldsToEdit.length === 0"
        :variant="fieldsToEdit.length > 0 ? 'primary' : ''"
        >Speichern</b-button
      >
      <b-button @click="hide">Abbrechen</b-button>
    </template>
  </b-modal>
</template>

<script>
import AirportsSucheMultiselect from '@/components/common/airports-suche-multiselect.vue';
import Multiselect from 'vue-multiselect';

export default {
  props: {
    title: { type: String, default: 'Reisetermine bearbeiten' },
    isLoading: { type: Boolean, default: false },
    country: { type: String, default: null },
    editOptions: {
      type: Array,
      required: true,
    },
  },
  components: {
    Multiselect,
    AirportsSucheMultiselect,
  },
  computed: {
    orderedFieldEditOptions() {
      return this.editOptions.slice().sort((a, b) => a.label.localeCompare(b.label));
    },
  },
  data() {
    return {
      fieldsToEdit: [],
    };
  },
  methods: {
    onShow() {
      this.fieldsToEdit = [];
    },
    onHide() {},
    handleSubmit() {
      this.$emit('handleSubmit', this.fieldsToEdit);
    },
  },
};
</script>
<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
</style>
