<template>
  <b-sidebar
    id="sidebar-flugplanung"
    backdrop-variant="dark"
    backdrop
    shadow
    right
    width="max(700px,50%)"
    @shown="onShow"
    @hidden="onHide"
  >
    <template #header="{ hide }">
      <div class="d-flex justify-content-between align-items-center w-100 ml-2">
        <div>
          <i class="fa-chevron-left fas icon-xl mr-2 navigationIcons" @click="goUpOneRow"></i>
          <i class="fa-chevron-right fas icon-xl mr-2 navigationIcons" @click="goDownOneRow"></i>
        </div>
        <h4>
          <b>{{ reisetermin?.reiseterminkuerzel }}</b>
        </h4>
        <div>
          <b-button class="mr-2 ml-auto" size="sm" @click="openModalWithData" v-b-modal.modalAufgabeEdit
            >Aufgabe erstellen</b-button
          >
          <b-button
            class="mr-4"
            size="sm"
            @click="saveChanges"
            :variant="disableSichernButton ? 'secondary' : 'success'"
            :disabled="disableSichernButton"
            >Sichern</b-button
          >
          <i class="fas fa-times m-3 navigationIcons" @click="hide"></i>
        </div>
      </div>
    </template>
    <b-overlay :show="isLoading" opacity="0.95">
      <div
        class="px-8 py-4 d-flex"
        v-if="reiseterminExists && Object.keys(this.reisetermin).length > 0"
        style="width: 100%; flex-direction: column; gap: 30px"
      >
        <CollapsibleWithItems
          :header="'Reise & Flugdaten'"
          :data="dataReiseUndFlugdaten"
          :isCollapsedOnMount="true"
        ></CollapsibleWithItems>
        <CollapsibleWithItemsEditable
          :country="reisetermin ? reisetermin.reise.reisekuerzel.substring(0, 2) : ''"
          :header="'Einkaufsdaten'"
          v-model="dataEinkaufsdaten"
          :isCollapsedOnMount="isEinkaufsdatenCollapsed"
        ></CollapsibleWithItemsEditable>
        <div v-if="reisetermin.metadata.inlandsfluege?.length > 0">
          <h5
            role="button"
            @click="isInlandsfluegeCollapsed = !isInlandsfluegeCollapsed"
            :aria-expanded="isInlandsfluegeCollapsed ? 'true' : 'false'"
            aria-controls="collapse-4"
            class="mb-2 user-select-none"
          >
            <b-icon
              v-if="!isInlandsfluegeCollapsed"
              :scale="0.75"
              icon="chevron-right"
              aria-hidden="true"
            ></b-icon>
            <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
            Inlandsflüge
          </h5>
          <hr class="w-75 ml-0" />
          <b-collapse
            id="collapse-4"
            :class="isInlandsfluegeCollapsed ? null : 'collapsed'"
            v-model="isInlandsfluegeCollapsed"
          >
            <div v-for="(data, i) in dataInlandsfluege" :key="'inlandflug-' + i" class="mt-5">
              <h6 class="mb-4 ml-2">{{ i + 1 + '. Inlandsflug' }}</h6>
              <b-list-group
                horizontal
                v-for="item in data"
                :key="'inlandsflug-' + i + item.label"
                class="bv-example-row"
              >
                <template v-if="item.show">
                  <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut" style="">
                    <h6 class="my-1 leftLabel">{{ item.label }}</h6>
                  </b-list-group-item>
                  <b-list-group-item
                    class="col-6 d-flex align-items-center"
                    :class="'detailLabelGreyedOut'"
                    :variant="item.variant"
                  >
                    <template v-if="item?.editable">
                      <b-form-input
                        v-if="item.editType === 'input'"
                        v-model="item.valueEdited"
                        class="border-0 p-2 leftLabel"
                        debounce="1000"
                        @update="changedFlugnummerOrInGruppeInkludiert(data)"
                        placeholder="-"
                      >
                      </b-form-input>
                      <b-form-checkbox
                        size="lg"
                        v-else-if="item.editType === 'checkbox'"
                        v-model="item.valueEdited"
                        @change="changedFlugnummerOrInGruppeInkludiert(data)"
                      ></b-form-checkbox>
                    </template>
                    <span v-else-if="item.renderer !== null">
                      <span v-html="item.renderer"></span>
                    </span>
                    <h6 class="my-1 leftLabel" v-else>{{ item.value }}</h6>
                  </b-list-group-item>
                </template>
              </b-list-group>
            </div>
          </b-collapse>
        </div>
        <div v-if="aufgabenTerminplanung?.length > 0">
          <h5
            role="button"
            @click="isAufgabenCollapsed = !isAufgabenCollapsed"
            :aria-expanded="isAufgabenCollapsed ? 'true' : 'false'"
            aria-controls="collapse-5"
            class="mb-2 user-select-none"
          >
            <b-icon
              v-if="!isAufgabenCollapsed"
              :scale="0.75"
              icon="chevron-right"
              aria-hidden="true"
            ></b-icon>
            <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
            Aufgaben
          </h5>
          <hr class="w-75 ml-0" />
          <b-collapse
            id="collapse-5"
            :class="isAufgabenCollapsed ? null : 'collapsed'"
            v-model="isAufgabenCollapsed"
          >
            <AufgabenWidget
              class="mt-3"
              :filter="'sidebar-flugplanung'"
              :ansicht="'sidebar-flugplanung'"
              :aufgaben="aufgabenTerminplanung || []"
            ></AufgabenWidget>
          </b-collapse>
        </div>
        <h5 class="mt-3 d-flex" style="flex-direction: column; gap: 16px">
          <div>
            <a :href="'#/reisetermine/' + reisetermin.id" target="_blank">
              <b-icon icon="box-arrow-up-right"></b-icon> Reisetermin Detailansicht
            </a>
          </div>
          <div>
            <a
              :href="
                '#/reisen/' +
                reisetermin.reise.reisekuerzel +
                '/reisestammdaten?reiseterminAbreisedatum=' +
                reisetermin.abreisedatum.substring(0, 10)
              "
              target="_blank"
            >
              <b-icon icon="box-arrow-up-right"></b-icon> Reisestammdaten
            </a>
          </div>
        </h5>
        <ReiseterminCardCommentBase
          v-if="activeComments"
          :reisekuerzel="reisetermin.reise.reisekuerzel"
          :reiseterminId="reisetermin.id"
          :startdatum="reisetermin.startdatum"
          :initalComments="reisetermin.comments"
        />
        <b-skeleton v-else width="100%" height="413px"></b-skeleton>
      </div>
    </b-overlay>
  </b-sidebar>
</template>
<script>
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import CollapsibleWithItems from '@/components/common/collapsible-with-items.vue';
import configPriority from '@/core/produkte/reisen/priority.config.json';
import regionsmanagerJson from '@/core/flugverfuegbarkeit/regionsmanager-reisekuerzel.json';
import CollapsibleWithItemsEditable from '@/components/common/collapsible-with-items-editable.vue';
import { einkaufsartOptions, statusgruppeOptions } from '@/core/common/helpers/utils.js';
import apiService from '@/core/common/services/api.service';
import { format, getISODay, sub } from 'date-fns';
import ReiseterminCardCommentBase from '@/components/produkte/reisetermine/reisetermin-card-comment-base.vue';
import { add, parseISO } from 'date-fns';

export default {
  props: { reisetermin: { type: Object } },
  components: {
    PaxWidget,
    CollapsibleWithItems,
    CollapsibleWithItemsEditable,
    AufgabenWidget,
    ReiseterminCardCommentBase,
  },
  computed: {
    aufgabenTerminplanung() {
      if (!this.reisetermin || this.reisetermin.aufgaben?.length === 0) {
        return null;
      }
      const kategorienToShow = [
        'TerminAbsagen',
        'OptionsfristErreicht',
        'GruppeneinkaufPruefen',
        'AnlageHotelstamm',
        'AnlageFlugstamm',
      ];

      return this.reisetermin.aufgaben.filter(aufgabe => kategorienToShow.includes(aufgabe.kategorie));
    },
    reiseterminExists() {
      if (this.reisetermin) return Object.keys(this.reisetermin)?.length > 0;

      return false;
    },
    dataReiseUndFlugdaten() {
      if (this.reiseterminExists) {
        return [
          {
            label: 'Priorität',
            value: this.reisetermin.reise.prioritaet,
            renderer: `<h6 class="mb-0"><span class="badge badge-${this.getVariantByPriority(
              this.reisetermin.reise.prioritaet
            )}">${this.getValueByPriority(this.reisetermin.reise.prioritaet)}</span></h6>`,
            variant: null,
          },
          {
            label: 'RM',
            value: regionsmanagerJson[this.reisetermin.reise.regionsmanager]?.name ?? '-',
            renderer: null,
            variant: null,
          },
          {
            label: 'Pax',
            value: null,
            renderer: 'paxWidget',
            variant: null,
          },
          {
            label: 'Erwartete Pax',
            value: this.reisetermin.metadata.erwartetePax ?? '-',
            renderer: null,
            variant: null,
          },
          {
            label: 'Strecke',
            value:
              (this.reisetermin.metadata.ziellandAbflughaefen?.map(fh => fh.flughafenIataCode).join('/') ??
                '') +
              '-' +
              (this.reisetermin.metadata.ziellandZielflughaefen?.map(fh => fh.flughafenIataCode).join('/') ??
                ''),
            renderer: null,
            variant: null,
          },
          {
            label: 'Abflug DE',
            value: this.reisetermin.abreisedatum
              ? format(new Date(this.reisetermin.abreisedatum), 'dd.MM.yyyy')
              : '-',
            renderer: null,
            variant: null,
          },
          {
            label: 'Rückflugdatum',
            value: this.reisetermin.hotelendUndRueckflugdatum
              ? format(new Date(this.reisetermin.hotelendUndRueckflugdatum), 'dd.MM.yyyy')
              : '-',
            renderer: null,
            variant: null,
          },
          {
            label: 'SKR Flugangebot',
            value: this.reisetermin.metadata.skrFlugangebot ?? '-',
            renderer: null,
            variant: null,
          },
          {
            label: 'Transferszenario Hin/Rück',
            value: null,
            renderer: `<h6 class="my-1">
                ${
                  (this.reisetermin.metadata.transferszenarioHin?.substring(0, 2) ?? '-') +
                  ' | ' +
                  (this.reisetermin.metadata.transferszenarioRueck?.substring(0, 2) ?? '-')
                }
                </h6>`,
            variant: null,
          },
          {
            label: 'VK kalk. Vornacht DZ p.P.',
            value: `${
              this.reisetermin.metadata.vkVornachtDzProPerson
                ? String(this.reisetermin.metadata.vkVornachtDzProPerson)?.replace('.', ',')
                : '0'
            }€ (${
              this.reisetermin.metadata.vkVornachtDzProPerson
                ? Math.round(this.reisetermin.metadata.vkVornachtDzProPerson / 10) * 10
                : '0'
            }€)`,
            renderer: null,
            variant: null,
          },
          {
            label: 'VK Transferkosten Hin',
            value: `${
              this.reisetermin.metadata.vkTransferkostenHin
                ? String(this.reisetermin.metadata.vkTransferkostenHin)?.replace('.', ',')
                : '0'
            }€ (${
              this.reisetermin.metadata.vkTransferkostenHin
                ? Math.round(this.reisetermin.metadata.vkTransferkostenHin / 10) * 10
                : '0'
            }€)`,
            renderer: null,
            variant: null,
          },
          {
            label: 'VK Transferkosten Rück',
            value: `${
              this.reisetermin.metadata.vkTransferkostenRueck
                ? String(this.reisetermin.metadata.vkTransferkostenRueck)?.replace('.', ',')
                : '0'
            }€ (${
              this.reisetermin.metadata.vkTransferkostenRueck
                ? Math.round(this.reisetermin.metadata.vkTransferkostenRueck / 10) * 10
                : '0'
            }€)`,
            renderer: null,
            variant: null,
          },
        ];
      } else return [];
    },
    dataInlandsfluege() {
      if (this.reiseterminExists) {
        return this.reisetermin.metadata.inlandsfluege
          .slice()
          .sort((a, b) => (a.tag === b.tag ? 0 : a.tag > b.tag ? 1 : -1))
          .map(inlandsflug => {
            return [
              {
                label: 'Tag / Verkehrstag / Datum',
                value:
                  inlandsflug.tag +
                  ' / ' +
                  this.getVerkehrstagOfInlandsflug(this.reisetermin, inlandsflug) +
                  ' / ' +
                  this.getDateOfIlandsflug(this.reisetermin, inlandsflug),
                renderer: null,
                variant: null,
                show: true,
              },
              {
                label: 'Einkauf',
                value: inlandsflug.einkauf,
                renderer: null,
                variant: null,
                show: true,
              },
              {
                label: 'Abflughafen / Zielflughafen',
                value: inlandsflug.abflughafenIataCode + ' / ' + inlandsflug.zielflughafenIataCode,
                renderer: null,
                variant: null,
                show: true,
              },
              {
                label: 'Früh. Abflug',
                value: inlandsflug.fruehesterAbflug?.substring(0, 5) ?? '-',
                renderer: null,
                variant: null,
                show: true,
              },
              {
                label: 'Spät. Ankunft',
                value: inlandsflug.spaetesteAnkunft?.substring(0, 5) ?? '-',
                renderer: null,
                variant: null,
                show: true,
              },
              {
                label: 'Flugnummer',
                value: inlandsflug.flugnummer,
                valueEdited: inlandsflug.flugnummer,
                renderer: null,
                variant: null,
                show: true,
                editable: true,
                editType: 'input',
              },
              {
                label: 'In Gruppe inkludiert',
                value: inlandsflug.inGruppeInkludiert,
                valueEdited: inlandsflug.inGruppeInkludiert,
                renderer: null,
                variant: null,
                show: true,
                editable: true,
                editType: 'checkbox',
              },
              {
                label: 'Id',
                value: inlandsflug.id,
                show: false,
              },
            ];
          });
      } else return [];
    },
    disableSichernButton() {
      return (
        this.dataEinkaufsdaten &&
        this.dataEinkaufsdaten
          .filter(item => item.editable)
          .every(
            item =>
              item.value === item.valueEdited ||
              JSON.stringify(item.value)?.replaceAll('[', '').replaceAll(']', '') ===
                JSON.stringify(item.valueEdited)?.replaceAll('[', '').replaceAll(']', '')
          )
      );
    },
  },
  data() {
    return {
      isReisedatenCollapsed: true,
      isEinkaufsdatenCollapsed: false,
      isInlandsfluegeCollapsed: false,
      isAufgabenCollapsed: true,
      activeComments: true,
      prefixedVerlinkungen: [],
      dataEinkaufsdaten: [],
      isLoading: false,
    };
  },
  watch: {
    reisetermin(v) {
      if (v) {
        this.setEinkaufsdaten(v);
      }
    },
  },
  mounted() {
    if (this.reiseterminExists) {
      this.setEinkaufsdaten();
    }
  },
  methods: {
    changedFlugnummerOrInGruppeInkludiert(inlandsflugData) {
      this.isLoading = true;

      const flugnummer = inlandsflugData.find(item => item.label === 'Flugnummer').valueEdited;
      const inGruppeInkludiert = inlandsflugData.find(
        item => item.label === 'In Gruppe inkludiert'
      ).valueEdited;
      const inlandsflugId = inlandsflugData.find(item => item.label === 'Id')?.value;

      const { id: reiseterminId } = this.reisetermin;

      apiService
        .put('Reisetermin/metadata/inlandsflug/bulkUpdateFlugnummerAndInGruppeInkludiert', {
          reiseterminIdToInlandsflugId: {
            [reiseterminId]: inlandsflugId,
          },
          flugnummer,
          inGruppeInkludiert,
        })
        .then(res => {
          this.toast('Flugnummer gespeichert!');
          this.$emit('inlandsflugFlugnummerChanged', {
            flugnummer,
            inGruppeInkludiert,
            reiseterminId,
            inlandsflugId,
          });
        })
        .finally(() => (this.isLoading = false));
    },
    getVerkehrstagOfInlandsflug(reisetermin, inlandsflug) {
      const dayOfInlandsflug = add(new Date(reisetermin.abreisedatum), { days: +inlandsflug.tag - 1 });
      const verkehrstag = getISODay(dayOfInlandsflug);
      return verkehrstag;
    },
    getDateOfIlandsflug(reisetermin, inlandsflug) {
      const dateOfInlandsfluge = add(new Date(reisetermin.abreisedatum), { days: +inlandsflug.tag - 1 });

      return format(dateOfInlandsfluge, 'dd.MM.yyyy');
    },
    formatParams() {
      const params = {
        [this.reisetermin.startdatum]: {
          statusgruppe: this.dataEinkaufsdaten[0].valueEdited || null,
          einkaufsart: this.dataEinkaufsdaten[1].valueEdited || null,
          gruppePnr: this.dataEinkaufsdaten[2].valueEdited || null,
          eingekauftePax: +this.dataEinkaufsdaten[3].valueEdited || null,
          mindestabnahmeAirline: this.dataEinkaufsdaten[4].valueEdited || null,
          gruppeFlughafenIataCode: this.dataEinkaufsdaten[5].valueEdited || null,
          flugnummerGruppeHin: this.dataEinkaufsdaten[6].valueEdited[0] || null,
          flugnummerGruppeRueck: this.dataEinkaufsdaten[6].valueEdited[1] || null,
          obClass: this.dataEinkaufsdaten[7].valueEdited[0] || null,
          ibClass: this.dataEinkaufsdaten[7].valueEdited[1] || null,
          optionsfrist: +this.dataEinkaufsdaten[8].valueEdited[0] || null,
          ticketfrist: +this.dataEinkaufsdaten[9].valueEdited[0] || null,
          hauptstreckePreisNetto: +this.dataEinkaufsdaten[10].valueEdited || null,
          inlandsflugPreis: +this.dataEinkaufsdaten[11].valueEdited || null,
          taxesFlug: +this.dataEinkaufsdaten[12].valueEdited || null,
        },
      };
      return params;
    },
    async saveChanges() {
      this.isLoading = true;
      const params = this.formatParams();
      const reisekuerzel = this.reisetermin.reiseterminkuerzel.substring(0, 6);
      apiService
        .put(`Reise/${reisekuerzel}/reisetermine/metadata/flugeinkauf`, params)
        .then(res => {
          if (res.data.result.isSuccess) {
            this.toast('Flugeinkauf erfolgreich bearbeitet.');
            this.$emit('singleFlugplanungBearbeitet', res.data.result.value);
            this.setEinkaufsdaten();
          }
        })
        .catch(() => (this.isLoading = false))
        .finally(() => (this.isLoading = false));
    },
    setEinkaufsdaten() {
      this.dataEinkaufsdaten = [
        {
          label: 'Status Gruppe',
          value: this.reisetermin.flugeinkauf?.statusgruppe,
          valueEdited: this.reisetermin.flugeinkauf?.statusgruppe,
          renderer: null,
          editable: true,
          editType: 'select',
          options: statusgruppeOptions,
        },
        {
          label: 'Einkaufsart',
          value: this.reisetermin.flugeinkauf?.einkaufsart,
          valueEdited: this.reisetermin.flugeinkauf?.einkaufsart,
          renderer: null,
          editable: true,
          editType: 'select',
          options: einkaufsartOptions,
        },
        {
          label: 'GRP PNR',
          value: this.reisetermin.flugeinkauf?.gruppePnr,
          valueEdited: this.reisetermin.flugeinkauf?.gruppePnr,
          renderer: null,
          editable: true,
          editType: 'input',
        },

        {
          label: 'Eingekaufte PAX',
          value: this.reisetermin.flugeinkauf?.eingekauftePax,
          valueEdited: this.reisetermin.flugeinkauf?.eingekauftePax,
          renderer: null,
          editable: true,
          editType: 'input',
        },
        {
          label: 'Mindestabnahme Airline',
          value: this.reisetermin.flugeinkauf?.mindestabnahmeAirline,
          valueEdited: this.reisetermin.flugeinkauf?.mindestabnahmeAirline,
          renderer: null,
          editable: true,
          editType: 'number',
        },
        {
          label: 'Gruppe Flughafen',
          value: this.reisetermin.flugeinkauf?.gruppeFlughafenIataCode,
          valueEdited: this.reisetermin.flugeinkauf?.gruppeFlughafenIataCode,
          renderer: null,
          editable: true,
          editType: 'flughafen',
        },
        {
          label: ['Flugnr. GRP Hin', 'Rück'],
          value: [
            this.reisetermin.flugeinkauf?.flugnummerGruppeHin,
            this.reisetermin.flugeinkauf?.flugnummerGruppeRueck,
          ],
          valueEdited: [
            this.reisetermin.flugeinkauf?.flugnummerGruppeHin,
            this.reisetermin.flugeinkauf?.flugnummerGruppeRueck,
          ],
          renderer: null,
          editable: [true, true],
          editType: ['input', 'input'],
          split: true,
        },
        {
          label: ['OB', 'IB Class'],
          value: [this.reisetermin.flugeinkauf?.obClass, this.reisetermin.flugeinkauf?.ibClass],
          valueEdited: [this.reisetermin.flugeinkauf?.obClass, this.reisetermin.flugeinkauf?.ibClass],
          renderer: null,
          editable: [true, true],
          editType: ['input', 'input'],
          split: true,
        },
        {
          label: ['Optionsfrist', 'Datum'],
          value: [
            this.reisetermin.flugeinkauf?.optionsfrist,
            this.reisetermin.flugeinkauf?.optionsfrist
              ? format(
                  sub(new Date(this.reisetermin.abreisedatum), {
                    days: this.reisetermin.flugeinkauf.optionsfrist,
                  }),
                  'dd.MM.yyyy'
                )
              : '-',
          ],
          valueEdited: [
            this.reisetermin.flugeinkauf?.optionsfrist,
            this.reisetermin.flugeinkauf?.optionsfrist
              ? format(
                  sub(new Date(this.reisetermin.abreisedatum), {
                    days: this.reisetermin.flugeinkauf.optionsfrist,
                  }),
                  'dd.MM.yyyy'
                )
              : '-',
          ],
          renderer: null,
          split: true,
          editable: [true, false],
          editType: ['number', 'number'],
          append: ['Tage', ''],
        },
        {
          label: ['Ticketfrist', 'Datum'],
          value: [
            this.reisetermin.flugeinkauf?.ticketfrist,
            this.reisetermin.flugeinkauf?.ticketfrist
              ? format(
                  sub(new Date(this.reisetermin.abreisedatum), {
                    days: this.reisetermin.flugeinkauf.ticketfrist,
                  }),
                  'dd.MM.yyyy'
                )
              : '-',
          ],
          valueEdited: [
            this.reisetermin.flugeinkauf?.ticketfrist,
            this.reisetermin.flugeinkauf?.ticketfrist
              ? format(
                  sub(new Date(this.reisetermin.abreisedatum), {
                    days: this.reisetermin.flugeinkauf.ticketfrist,
                  }),
                  'dd.MM.yyyy'
                )
              : '-',
          ],
          renderer: null,
          split: true,
          editable: [true, false],
          editType: ['number', 'number'],
          append: ['Tage', ''],
        },
        {
          label: 'Preis Netto',
          value: this.reisetermin.flugeinkauf?.hauptstreckePreisNetto,
          valueEdited: this.reisetermin.flugeinkauf?.hauptstreckePreisNetto,
          renderer: null,
          editable: true,
          editType: 'number',
          append: '€',
        },
        {
          label: '€-Summe aller INFL',
          value: this.reisetermin.flugeinkauf?.inlandsflugPreis,
          valueEdited: this.reisetermin.flugeinkauf?.inlandsflugPreis,
          renderer: null,
          editable: true,
          editType: 'number',
          append: '€',
        },
        {
          label: 'Taxes',
          value: this.reisetermin.flugeinkauf?.taxesFlug,
          valueEdited: this.reisetermin.flugeinkauf?.taxesFlug,
          renderer: null,
          editable: true,
          editType: 'number',
          append: '€',
        },
        {
          label: 'Gesamtpreis GRP',
          value: this.reisetermin.gesamtpreisFlugeinkauf + '€',
          renderer: null,
          editable: false,
        },
      ];
    },
    onShow() {
      this.addKeyEventListener();
    },
    onHide() {
      this.$emit('sidebarHide');
      this.removeKeyEventListener();
    },
    goUpOneRow() {
      this.activeComments = false;
      this.$emit('rowSelectionChanged', 'up');
      setTimeout(() => {
        this.activeComments = true;
      }, 100);
    },
    goDownOneRow() {
      this.activeComments = false;
      this.$emit('rowSelectionChanged', 'down');
      setTimeout(() => {
        this.activeComments = true;
      }, 100);
    },
    addKeyEventListener() {
      window.addEventListener('keydown', this.handleKeyPress);
    },
    removeKeyEventListener() {
      window.removeEventListener('keydown', this.handleKeyPress);
    },
    handleKeyPress($event) {
      if ($event.key === 'ArrowLeft') {
        this.goUpOneRow();
      } else if ($event.key === 'ArrowRight') {
        this.goDownOneRow();
      }
    },
    openModalWithData() {
      this.prefixedVerlinkungen = [
        {
          id: 0,
          typ: 'Reisetermin',
          artifactId: this.reisetermin.id,
          artifactText: this.reisetermin.reiseterminkuerzel,
        },
      ];
      this.$emit('showModalAufgabeEdit', this.prefixedVerlinkungen);
    },
    getVariantByPriority(priority) {
      return configPriority[priority].color;
    },
    getValueByPriority(priority) {
      return configPriority[priority].value;
    },
  },
};
</script>
<style scoped>
.normalFormatted {
  border-style: solid;
  border-color: rgba(114, 114, 114, 0.15);
}
.detailLabelGreyedOut {
  background-color: rgba(114, 114, 114, 0.05);
  border-style: solid;
  border-color: rgba(114, 114, 114, 0.15);
}
.navigationIcons {
  color: black;
}
.navigationIcons:hover {
  color: #0d6efd;
  cursor: pointer;
}
.leftLabel {
  font-size: 1.05rem;
  font-weight: 500;
}
:deep(.form-control) {
  height: 32px !important;
}
.list-group {
  height: 42px;
}
</style>
