<template>
  <div>
    <h5
      role="button"
      @click="isCollapsed = !isCollapsed"
      class="user-select-none"
      :aria-expanded="isCollapsed ? 'true' : 'false'"
      aria-controls="collapse"
    >
      <b-icon v-if="!isCollapsed" :scale="0.75" icon="chevron-right" aria-hidden="true"></b-icon>
      <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
      {{ header }}
    </h5>
    <hr class="w-75 ml-0" />
    <b-collapse id="collapse" :class="isCollapsed ? null : 'collapsed'" v-model="isCollapsed">
      <b-list-group horizontal class="bv-example-row" v-for="item in data" :key="'reisetermin' + item.label">
        <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut" style="">
          <h6 class="my-1 leftLabel">{{ item.label }}</h6>
        </b-list-group-item>
        <b-list-group-item
          class="col-6 d-flex align-items-center"
          :class="'detailLabelGreyedOut'"
          :variant="item.variant"
        >
          <span v-if="item.renderer !== null">
            <span v-if="item.renderer === 'paxWidget'">
              <PaxWidget :paxObject="data?.pax" :kalkPax="data?.metadata?.kalkPax"></PaxWidget>
            </span>
            <span v-else v-html="item.renderer"></span>
          </span>

          <h6 v-else class="w-100 mb-0 leftLabel" :contenteditable="item.editable">
            {{ item.value }}
            <template v-if="item?.append" class="ml-1">{{ item.append }}</template>
          </h6>
        </b-list-group-item>
      </b-list-group>
    </b-collapse>
  </div>
</template>
<script>
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';

export default {
  props: {
    isCollapsedOnMount: { type: Boolean, default: false },
    data: { type: Array, required: true },
    header: { type: String, requred: true },
  },
  components: { PaxWidget },
  data() {
    return {
      isCollapsed: false,
    };
  },
  mounted() {
    this.isCollapsed = this.isCollapsedOnMount;
  },
  methods: {},
};
</script>
<style scoped>
.normalFormatted {
  border-style: solid;
  border-color: rgba(114, 114, 114, 0.15);
}
.detailLabelGreyedOut {
  background-color: rgba(114, 114, 114, 0.05);
  border-style: solid;
  border-color: rgba(114, 114, 114, 0.15);
}
.leftLabel {
  font-size: 1.05rem;
  font-weight: 500;
}
.list-group {
  height: 42px;
}
</style>
